<template>
  <div class="series-podcast pointer has-edit-button">
    <ion-card
      color="medium"
      class="series-podcast-card"
    >
      <EditButton
        v-if="canEdit"
        :url="`courses/${courseData.id}/edit`"
      />
      <ion-grid v-if="gridLayout">
        <ion-row>
          <ion-col class="img-col">
            <div class="img-wrap">
              <AppImg
                :img-src="banner"
                img-alt="banner"
                img-classes="pb-100"
                border-radius="10px"
              />
            </div>
          </ion-col>
          <ion-col class="card-content">
            <ion-card-header class="series-podcast-card-header">
              <p class="title fz-14 fw-500 mb-2 is-displaying-two-lines-text">{{ courseData.course_title }}</p>
              <p class="fz-12 fw-300 is-displaying-two-lines-text">{{ courseData.course_introduction }}</p>
            </ion-card-header>
            <ion-card-content class="series-podcast-card-content is-flex">
              <div class="series-quantity">
                <p class="fw-300">{{ courseData.episodes }} {{ $t('podcast.episodes') }}</p>
              </div>
              <div class="social-logo is-flex ion-align-items-center">
                <div
                  v-if="isButtons"
                  class="podcast-social is-flex"
                >
                  <ShareButton
                    :text="courseData.title"
                    :course-id="courseData.id"
                  />
                  <FavouriteButton
                    :course-id="courseData.id"
                    @update:favorites="$emit('update:favorites')"
                  />
                </div>
                <div
                  v-if="isLogo"
                  class="company-logo ion-margin-start"
                >
                  <AppImg
                    :img-src="companyLogo"
                    img-alt="company logo"
                    border-radius="10px"
                  />
                </div>
              </div>
            </ion-card-content>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div
        v-else
        class="card-content"
      >
        <ion-card-header class="series-podcast-card-header">
          <p class="title fz-18 fw-500 mb-2 is-displaying-two-lines-text">{{ courseData.course_title }}</p>
          <p class="fz-12 fw-300 is-displaying-two-lines-text">{{ courseData.course_introduction }}</p>
        </ion-card-header>
        <ion-card-content class="series-podcast-card-content is-flex">
          <div class="series-quantity">
            <p class="fw-300">{{ courseData.episodes }} {{ $t('podcast.episodes') }}</p>
          </div>
          <div class="social-logo is-flex ion-align-items-center">
            <div
              v-if="isButtons"
              class="podcast-social is-flex"
            >
              <ShareButton
                :text="courseData.title"
                :course-id="courseData.id"
              />
              <FavouriteButton
                :course-id="courseData.id"
                @update:favorites="$emit('update:favorites')"
              />
            </div>
            <div
              v-if="isLogo"
              class="company-logo ion-margin-start"
            >
              <AppImg
                :img-src="companyLogo"
                img-alt="company logo"
                border-radius="10px"
              />
            </div>
          </div>
        </ion-card-content>
        <div class="img-wrap">
          <AppImg
            :img-src="banner"
            img-alt="banner"
            img-classes="pb-100"
            border-radius="10px"
          />
        </div>
      </div>

    </ion-card>
  </div>
</template>

<script>
import { IonCard, IonCardHeader, IonCardContent, IonImg, IonGrid, IonCol, IonRow } from '@ionic/vue';
import AppImg from '@/components/common/AppImg.vue';
import ShareButton from '@/components/common/ShareButton.vue';
import FavouriteButton from '@/components/common/FavouriteButton.vue';
import EditButton from '@/components/common/EditButton.vue';

import { computed } from '@vue/reactivity';
import { getSmallestSizeImage } from "@/helpers/functions";
import { useStore } from 'vuex';

export default {
  name: 'SeriesPodcast',
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonImg,
    IonGrid,
    IonCol,
    IonRow,
    AppImg,
    ShareButton,
    FavouriteButton,
    EditButton,
  },
  props: {
    courseData: {
      type: Object,
      default: () => {
        return {
          course_title: '',
          course_introduction: '',
          episodes: 0,
          media: [],
        };
      },
    },
    isButtons: {
      type: Boolean,
      default: true,
    },
    isLogo: {
      type: Boolean,
      default: false,
    },
    gridLayout: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:favorites'],

  setup(props) {
    const store = useStore();

    const banner = computed(() => {
      return getSmallestSizeImage(props.courseData?.media)?.url ?? null;
    });
    const companyLogo = computed(() => {
      return getSmallestSizeImage(props.courseData?.company?.media)?.url ?? null;
    });

    const canEdit = computed(() => {
      const hasPermission = store.getters['authentication/hasPermission']('update_course');
      const isWeb = store.state.app.platform === 'web';
      return hasPermission && isWeb;
    });

    return {
      banner,
      companyLogo,
      canEdit,
    };
  },
};
</script>

<style scoped lang="scss">
.series-podcast-card {
  margin-left: auto;
  margin-right: auto;

  .img-wrap {
    border-radius: 10px;
    overflow: hidden;
    flex-grow: 1;
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.series-podcast-card-header {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.series-podcast-card-content {
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  padding-bottom: 0px;

  .series-quantity p {
    font-size: 12px;
  }
}
.podcast-social >:first-child {
  /*column-gap: 10px;*/
  margin-right: 10px;
}
.company-logo {
  width: 50px;
  height: 50px;
}
.img-col {
  display: flex;
  align-items: center;
  max-width: 170px;
  min-width: 100px;
}



ion-icon {
  font-size: 1.5rem;
}

ion-card {
  border-radius: 10px;
}

ion-grid {
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
}

ion-img {
  object-fit: cover;
  height: 100%;
}
ion-row {
  flex-wrap: nowrap;
}

@media screen and (min-width: 768px) {
  .img-col {
    max-width: 200px;
  }
}
</style>
