<template>
  <div class="single-podcast pointer has-edit-button">
    <ion-card
      color="medium"
      class="single-podcast-card"
    >
      <EditButton
        v-if="canEdit"
        :url="`streams/${podcastData.id}/edit`"
      />
      <ion-grid class="single-podcast-main">
        <ion-row>
          <ion-col class="img-col">
            <div class="img-wrap">
              <AppImg
                :img-src="banner"
                img-alt="banner"
                border-radius="10px"
              />
            </div>
          </ion-col>
          <ion-col>
            <div class="is-flex is-flex-direction-column ion-justify-content-center ion-margin-start h-100">
              <p class="fw-500 fz-14 title is-displaying-two-lines-text">
                {{ podcastData.title || podcastData.stream_title }}
              </p>
              <div class="fz-12 fw-300 subtitle">
                <span>
                  <span>{{ podcastDate }}</span>
                  <span
                    v-if="podcastData.duration"
                    class="bullet"
                  >&bull;</span>
                </span>
                <span v-if="podcastData.duration">{{ Math.round(podcastData.duration / 60) }}  min</span>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <p class="fz-12 fw-300 mb-3 mt-3 is-displaying-two-lines-text">{{ podcastData.description }}</p>

      <div class="single-podcast-footer is-flex">
        <PlayerReproduction
          :duration="podcastData.duration"
          :current-time="currentTime"
        />
        <div class="podcast-social is-flex ion-align-items-center">
          <ShareButton
            :text="podcastData.title"
            :course-id="podcastData.course_id"
            :stream-id="podcastData.id"
          />
          <FavouriteButton
            :course-id="podcastData.course_id"
            :stream-id="podcastData.id"
            @update:favorites="$emit('update:favorites')"
          />
        </div>
      </div>
    </ion-card>
  </div>
</template>

<script>
import PlayerReproduction from '@/components/common/PlayerReproduction.vue';
import AppImg from '@/components/common/AppImg.vue';
import ShareButton from '@/components/common/ShareButton.vue';
import FavouriteButton from '@/components/common/FavouriteButton.vue';
import EditButton from '@/components/common/EditButton.vue';

import { useI18n } from 'vue-i18n/index';

import { CATEGORY_GENERAL_MEDIA, CATEGORY_BANNER_MEDIA, CATEGORY_STREAM_STILL, CATEGORY_PODCAST_IMG } from '@/config/constants';

const PRIOR_IMAGES = [
  CATEGORY_PODCAST_IMG,
  CATEGORY_STREAM_STILL,
  CATEGORY_BANNER_MEDIA,
  CATEGORY_GENERAL_MEDIA,
];

import {
  IonIcon,
  IonCard,
  IonImg,
  IonGrid,
  IonCol,
  IonRow,
} from '@ionic/vue';
import { heartOutline, heart, playCircle } from 'ionicons/icons';

import {computed, ref} from '@vue/reactivity';
import {
  getLatestTrackPositionByTimestamp,
  getPlayerLatestPosition,
  getSecondsFromHMSString,
  getSmallestSizeImage,
  getStreamItemFromStorage,
} from "@/helpers/functions";
import {useStore} from "vuex";

export default {
  name: 'SinglePodcast',
  components: {
    IonIcon,
    IonCard,
    IonImg,
    IonGrid,
    IonCol,
    IonRow,
    PlayerReproduction,
    AppImg,
    ShareButton,
    FavouriteButton,
    EditButton,
  },
  props: {
    podcastData: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    courseMedia: {
      type: Array,
      default: () => ([]),
    },
  },

  emits: ['update:favorites'],

  setup(props) {
    const { t } = useI18n();
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const store = useStore();

    const canEdit = computed(() => {
      const hasPermission = store.getters['authentication/hasPermission']('update_stream');
      const isWeb = store.state.app.platform === 'web';
      return hasPermission && isWeb;
    });

    const podcastDate = computed(() => {
      const date = props.podcastData.rss_feed_publish_date
        ? new Date(props.podcastData.rss_feed_publish_date)
          .toLocaleDateString(t('podcast.local'), options)
        : new Date(props.podcastData.created_at)
          .toLocaleDateString(t('podcast.local'),options);
      return date !== 'Invalid Date' ? date : '';
    });

    const banner = computed(() => {
      const validStreamImages = props.podcastData.media.filter(media => PRIOR_IMAGES.includes(media.category));
      validStreamImages.sort((a,b) => b.category - a.category);
      const streamImg = getSmallestSizeImage(validStreamImages)?.url ?? null;
      const courseImg = getSmallestSizeImage(props.courseMedia)?.url ?? null;
      return streamImg || courseImg;
    } );

    const currentTime = computed(() => {
      const propData = props.podcastData.stream_user?.[0] ?? props.podcastData;
      const storageData = store.state.player.recentlyPlayedProgress[props.podcastData.id];
      const time = getLatestTrackPositionByTimestamp(propData, storageData);
      // console.log('time is');
      if (time) {
        return getSecondsFromHMSString(time);
      }
      return null;
    } );

    return {
      heartOutline,
      heart,
      playCircle,
      banner,
      podcastDate,
      currentTime,
      canEdit,
    };
  },
};
</script>

<style scoped lang="scss">
.single-podcast-card {
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
.single-podcast-main {
  .img-wrap {
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
  }
  .bullet {
    padding: 0 10px;
  }
  ion-row {
    height: 90px;
  }
}
.single-podcast-description {
  justify-content: center;
}
.title {
  margin: 10px 0;
}
.subtitle {
  margin-bottom: 10px;
}

.podcast-social >:first-child {
  margin-right: 10px;
}



.single-podcast-footer {
  justify-content: space-between;
  >:first-child {
    margin-right: 40px;
  }
}
ion-icon {
  font-size: 1.5rem;
}
ion-img {
  object-fit: cover;
  height: 100%;
}
ion-grid {
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
}

.img-col {
  flex-basis: 90px;
  flex-grow: unset;
}
</style>
